import { GetHomeBuyingJourneyWorkPartyHbj } from '@home-in/models'

export const getConveyancerNamesUsingHbjIds = (
  hbjIds: string[] | undefined,
  team?: GetHomeBuyingJourneyWorkPartyHbj[]
) => {
  // There should never be a case where the team has not been assigned if the user has HBJs
  // this prevents the intercom update to replace the conveyancer names with a empty array
  if (!team) return undefined

  return hbjIds
    ?.map((hbjId) => team?.find((party) => party.hbj_id === hbjId)?.partner_law_firm?.full_name)
    .filter((item) => item !== undefined)
}
