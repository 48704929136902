import { isAnyOf } from '@reduxjs/toolkit'
import { startAppListening } from '@redux/listeners'
import {
  initAnalytics,
  setAnalyticsInitialised,
  setPageReady,
  setUserAlias,
  setUserProfile,
  setUserProfileOnce,
  setUserIdentifier,
  trackButtonClick,
  trackClientError,
  trackEvent,
  trackPageView,
  trackRequestFailure,
  trackRequestStart,
  trackRequestSuccess,
} from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import {
  aliasUser,
  setOnceUserProfile,
  doSetUserProfile,
  doTrackButtonClick,
  doTrackClientError,
  doTrackEvent,
  doTrackPageView,
  doTrackRequestFailure,
  doTrackRequestStart,
  doTrackRequestSuccess,
  identifyUser,
  initAnalytics as init,
} from '@utils/helpers/analytics.helpers'
import { DeviceInfo } from '@utils/helpers/analytics.interface'
import { getActiveTransactions } from '@utils/helpers/buy-properties.helpers'

// By using store events, we are able to send these events within the RTK Query API handler rather than needing to plug in logic on the UI layer
export const analyticsListeners = () => {
  startAppListening({
    // Match all events in the analytics reducer
    matcher: isAnyOf(
      trackEvent,
      trackPageView,
      trackButtonClick,
      trackRequestStart,
      trackRequestFailure,
      trackRequestSuccess,
      trackClientError
    ),
    effect: async (action, api) => {
      const platform = typeof window !== 'undefined' ? window._platform || 'web' : 'web'
      const deviceInfo: DeviceInfo | undefined = window?._deviceInfo

      const homeState = api.getState().home
      const activeTransactions = getActiveTransactions(homeState)

      // This is a temporary hack to get the transaction phase
      // FIXME: For multiple transactions, we need to get the phase for the correct transaction or send phase for every transaction - TBD based on product requirements
      // https://home-in.atlassian.net/browse/TREE-1166
      const phase = activeTransactions?.[0]?.transaction_phase

      // [event].match(action) will return true if the action matches
      // i.e. 'dispatch(trackEvent(event))` will map to `doTrackEvent(event)`
      const tracker = [
        { matcher: trackEvent.match(action), function: doTrackEvent },
        { matcher: trackPageView.match(action), function: doTrackPageView },
        { matcher: trackButtonClick.match(action), function: doTrackButtonClick },
        { matcher: trackRequestStart.match(action), function: doTrackRequestStart },
        { matcher: trackRequestSuccess.match(action), function: doTrackRequestSuccess },
        { matcher: trackRequestFailure.match(action), function: doTrackRequestFailure },
        { matcher: trackClientError.match(action), function: doTrackClientError },
      ]
        // Find the first match
        .find(({ matcher }) => !!matcher)

      // Run the related function
      tracker?.function({ ...action.payload, platform, deviceInfo, phase })
    },
  })

  startAppListening({
    actionCreator: initAnalytics,
    effect: async (action, api) => {
      if (api.getState().analytics.initialised) return

      init()
      api.dispatch(setAnalyticsInitialised(true))
    },
  })

  startAppListening({
    actionCreator: setUserAlias,
    effect: async (action, api) => {
      if (!api.getState().analytics.initialised) return

      aliasUser(action.payload)
    },
  })

  startAppListening({
    actionCreator: setUserProfileOnce,
    effect: async (action, api) => {
      if (!api.getState().analytics.initialised) return

      setOnceUserProfile(action.payload)
    },
  })

  startAppListening({
    actionCreator: setUserProfile,
    effect: async (action, api) => {
      if (!api.getState().analytics.initialised) return

      doSetUserProfile(action.payload)
    },
  })

  startAppListening({
    actionCreator: setUserIdentifier,
    effect: async (action, api) => {
      if (!api.getState().analytics.initialised) return

      identifyUser(action.payload)
    },
  })

  startAppListening({
    actionCreator: setPageReady,
    effect: async (action, api) => {
      const state = api.getOriginalState()

      // Don't call twice
      if (!action.payload || !!state.analytics.pageIsReady) {
        return
      }

      const title = api.getState().analytics.title

      title && api.dispatch(trackPageView({ action: title, category: AnalyticsCategories.PageView }))
    },
  })
}
