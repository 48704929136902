import { ErrorSeverity } from '@home-in/models'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NextRouter, useRouter } from 'next/router'
import React, { FC, PropsWithChildren, useMemo } from 'react'
import { ActionButton, ActionButtonEnum } from '@elements/actions/action-button'
import { Error as ErrorElement } from '@elements/error'
import { IconButton, Icons } from '@elements/icons/icon'
import Logo from '@elements/logo'
import { Modal } from '@elements/modal'
import { Navigation } from '@elements/navigation'
import { FullPageLoader } from '@elements/status-handlers/full-page-loader'
import { useDebouncedRedirect } from '@hooks/useDebouncedRedirect'
import useIntercomSetup from '@hooks/useIntercomSetup'
import useLaunchDarklySetup from '@hooks/useLaunchDarklySetup'
import { useGetHomeBuyingJourneyQuery } from '@redux/apis/home'
import { useGetProfileQuery } from '@redux/apis/profile'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import { toggleModal } from '@redux/reducers/modal'
import { classNames } from '@utils/helpers/classNameHelper'
import { isLender } from '@utils/helpers/user.helpers'
import { ErrorBoundary } from '../../error-boundary'
import { LenderAuthenticatedWrapperInner } from './lender-authenticated-wrapper'

export const desktopNavLinkBannerId = 'desktop-nav-link-banner'

export interface AuthenticatedWrapperProps {
  actionButton?: ActionButtonEnum
  actionButtonLink?: string
  title?: string
  // This is important so we don't ruin analytics by changing the title tag which is used for page views
  overridePageTitleForUI?: string
  subtitle?: string
  showBack?: boolean
  showHbjTitle?: boolean
  hidePageTitle?: boolean
  hideNav?: boolean
  hideTopBar?: boolean
  whiteBgOnMobile?: boolean
  hideTitlePrefix?: boolean
  hideTitleOverride?: boolean
}

export const AuthenticatedWrapper = (props: PropsWithChildren<AuthenticatedWrapperProps>) => {
  const user = useAppSelector((state) => state.auth.user)
  const loaded = useAppSelector((state) => state.auth.loaded)

  return !loaded ? (
    <FullPageLoader />
  ) : isLender(user) ? (
    <LenderAuthenticatedWrapperInner {...props} />
  ) : (
    <AuthenticatedWrapperInner {...props} />
  )
}

const GoBackButton: FC<{ router: NextRouter }> = ({ router }) => (
  <>
    <div className="absolute left-0 hidden sm:block">
      <IconButton
        asset={Icons.arrowBack}
        className="text-secondary"
        size={16}
        callback={() => {
          window?.scrollTo?.(0, 0)
          router.back()
        }}
      />
    </div>
    <div className="block sm:hidden">
      <IconButton
        asset={Icons.arrowBack}
        className="text-secondary"
        size={16}
        callback={() => {
          window?.scrollTo?.(0, 0)
          router.back()
        }}
      />
    </div>
  </>
)

export const AuthenticatedWrapperInner = ({
  actionButton,
  actionButtonLink,
  children,
  title,
  subtitle,
  showBack,
  showHbjTitle,
  hidePageTitle,
  hideNav,
  hideTopBar,
  whiteBgOnMobile,
  hideTitlePrefix,
  overridePageTitleForUI,
  hideTitleOverride,
}: PropsWithChildren<AuthenticatedWrapperProps>) => {
  useIntercomSetup()
  useLaunchDarklySetup()
  const router = useRouter()
  const loaded = useAppSelector((state) => state.auth.loaded)
  const profile = useAppSelector((state) => state.profile)
  const { title: titleOverride, subtitle: subtitleOverride } = useAppSelector((state) => state.page)

  const { error: profileError } = useGetProfileQuery()
  const { error: homeError } = useGetHomeBuyingJourneyQuery()

  const computedTitle = useMemo(() => {
    if (overridePageTitleForUI) {
      return overridePageTitleForUI
    }
    return titleOverride ?? (title || '')
  }, [titleOverride, overridePageTitleForUI, title])

  const { whiteBackground, enhanceAddProperty } = useFlags()

  const computedSubtitle = useMemo(() => subtitleOverride ?? (subtitle || ''), [subtitleOverride, subtitle])
  // This `hideTitleOverrideLogic` is a hack to hide the title of a page but behind a feature flag (you can't pass useFlags into getStaticProps)
  const hideTitleOverrideLogic = enhanceAddProperty && hideTitleOverride
  const renderTitle =
    (!!computedTitle || !!computedSubtitle || showBack || showHbjTitle || !!actionButton) &&
    !hidePageTitle &&
    !hideTitleOverrideLogic

  // With auth0 OOTB signup, users' profile would not exist before onboarding
  // So, we don't want users to see an error flashing on the home screen before redirecting to `/welcome` page.
  const isAppLoading = !loaded || (!profile?.id && !profileError && router.pathname !== '/welcome')

  useDebouncedRedirect({
    redirect: '/welcome',
    condition: loaded && !profile?.accepted_terms,
    dependencies: [loaded, profile.accepted_terms],
  })

  // TODO: Migrate all modals to this singular modal controlled by state (https://projectwavec.atlassian.net/browse/BEL-710)
  const dispatch = useAppDispatch()
  const showModal = useAppSelector((state) => state.modal.active)
  const modalContent = useAppSelector((state) => state.modal.content)

  return (
    <>
      <div id="authed-page-wrapper" className={classNames({ hidden: isAppLoading })}>
        <main className="mobile-safe-vh flex flex-col md:min-h-screen">
          <section
            className={classNames(
              `flex flex-1 pb-16 md:pb-0 ${whiteBackground ? 'bg-white' : 'md:bg-light'}`,
              whiteBgOnMobile && 'bg-white'
            )}
          >
            {profileError || homeError ? (
              <div className="mx-auto">
                <Logo innerClassName="w-[8.5rem]" />
                <ErrorElement
                  error={new Error(`Unable to load ${profileError ? 'profile' : 'home'}. Showing fallback.`)}
                  severity={ErrorSeverity.ERROR}
                  subheading="Something went wrong"
                  message="Don't worry, you didn't break the internet! We've sent a report of this issue to our staff."
                  buttonType="exception"
                  showGoToHomepageButton={false}
                />
              </div>
            ) : (
              <>
                {/* Dont show nav on `Support Inbox` on mobile */}
                {!hideNav && <Navigation />}
                <div
                  className={classNames(
                    whiteBgOnMobile || whiteBackground ? 'bg-white' : 'bg-light',
                    `flex-1 p-2 md:p-6  md:ml-60 ${!whiteBackground && 'md:bg-light'} w-full mb-16 md:mb-0 md:mt-6`,
                    hideTopBar && 'md:mt-0',
                    hideNav && 'md:ml-0'
                  )}
                >
                  <div className="mx-auto flex h-full min-h-full max-w-6xl flex-col">
                    {renderTitle ? (
                      <div className="mobile-padding-top relative my-4 flex items-center md:mb-6 md:mt-0">
                        {showBack ? (
                          <GoBackButton router={router} />
                        ) : (
                          /* This is intentional. This is used to make sure the add property button
                          doesn't make everything off center. We position the button absolute on desktop so no issues */
                          <div className="box-content block w-4 p-2.5 sm:hidden"></div>
                        )}

                        <div className="grow text-center sm:px-10">
                          <>
                            {computedTitle && (
                              <h1 className={computedSubtitle ? 'text-h6' : 'text-h6 md:text-h5'}>{computedTitle}</h1>
                            )}
                            {computedSubtitle && <div className="mb-2 mt-1 text-sm">{computedSubtitle}</div>}{' '}
                          </>
                        </div>

                        <div className="block sm:hidden">
                          <ActionButton action={actionButton} actionLink={actionButtonLink} />
                        </div>
                        {/* The second action button is for web so the text is still centered */}
                        <div className="absolute right-0 hidden sm:block">
                          <ActionButton action={actionButton} actionLink={actionButtonLink} />
                        </div>
                      </div>
                    ) : (
                      // This is a hack to add some top margin on mobile when there is no title
                      // without this margin the content is too close to the top of the screen
                      <div className="mt-4 sm:hidden"></div>
                    )}

                    <ErrorBoundary key={router.asPath}>
                      <div className="flex-auto">{children}</div>
                    </ErrorBoundary>
                  </div>
                </div>
              </>
            )}
          </section>
        </main>

        <Modal
          size="medium"
          active={showModal}
          toggler={() => {
            dispatch(toggleModal())
          }}
        >
          {(showModal && modalContent) || null}
        </Modal>
      </div>
      {isAppLoading && <FullPageLoader />}
    </>
  )
}
