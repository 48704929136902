import {
  PigeonCommonResponseSchemaType,
  PigeonGetChecklistsResponseSchemaType,
  SellChecklistTaskId,
  SellSideChecklistItemType,
} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { buildTasksForSellSide } from '@features/checklist/data/checklist-builder'
import { getTaskTitleForSellSide } from '@features/checklist/data/task-content'
import { SellChecklist } from '@redux/reducers/sell'
import { trackEventsInApiCall } from '@redux/utils/analytics'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { baseQueryWithHeaders } from './custom-base-query'
import { sellPropertyApi } from './sell-property'

const checklistCacheTags = ['Checklists'] as const

export const sellChecklistApi = createApi({
  reducerPath: 'sellChecklistApi',
  baseQuery: baseQueryWithHeaders('pigeon/v1/'),
  tagTypes: checklistCacheTags,
  endpoints: (builder) => ({
    addSellChecklist: builder.mutation<PigeonCommonResponseSchemaType, { propertyId: string }>({
      query: (body) => ({ url: 'checklist', method: 'POST', body }),
      invalidatesTags: ['Checklists'],
      transformResponse: (response: PigeonCommonResponseSchemaType) => response,
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.AddSellChecklist,
            category: AnalyticsCategories.Property,
          },
        })

        await queryFulfilled
        // We need to invalidate the properties tag to refetch the properties list
        // We don't do this in the addSellProperty because it prematurely refreshes the page
        // When you're adding a sell property
        dispatch(sellPropertyApi.util.invalidateTags(['Properties']))
      },
    }),
    getAllSellChecklists: builder.query<SellChecklist[], void>({
      query: () => ({ url: 'checklists', method: 'GET' }),
      keepUnusedDataFor: 0,
      providesTags: ['Checklists'],
      transformResponse: (response: PigeonGetChecklistsResponseSchemaType) =>
        response?.map((checklist) => {
          const rawTasks = checklist.checklist
          const tasks = buildTasksForSellSide({ sellChecklistItems: rawTasks as SellSideChecklistItemType[] })
          return { ...checklist, tasks }
        }),
    }),
    completeSellChecklistTask: builder.mutation<
      PigeonCommonResponseSchemaType,
      { propertyId: string; taskId: SellChecklistTaskId }
    >({
      query: (body) => ({ url: 'checklist', method: 'PATCH', body }),
      invalidatesTags: ['Checklists'],
      transformResponse: (response: { data: PigeonCommonResponseSchemaType }) => response?.data,
      async onQueryStarted({ taskId }, { dispatch, queryFulfilled }) {
        const taskTitle = getTaskTitleForSellSide({ taskId })

        // Fire mixpanel event
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: 'Complete Sell Task',
            category: AnalyticsCategories.Checklist,
            value: taskTitle || `Task title not found ${taskId}`,
          },
        })
      },
    }),
  }),
})

export const { useAddSellChecklistMutation, useCompleteSellChecklistTaskMutation, useGetAllSellChecklistsQuery } =
  sellChecklistApi
