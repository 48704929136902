import { ActionCreatorWithPayload, addListener, AnyAction, createListenerMiddleware } from '@reduxjs/toolkit'

import { Matcher } from '@reduxjs/toolkit/dist/query/core/buildThunks'
import { analyticsListeners } from '@redux/listeners/analytics.listeners'
import { apiListeners } from '@redux/listeners/api.listeners'
import { checklistListeners } from '@redux/listeners/checklist.listeners'
import { userListeners } from '@redux/listeners/user.listeners'
import { notificationsListeners } from './listeners/notifications.listeners'
import type { AppDispatch, RootState } from './store'
import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit'

export const listenerMiddleware = createListenerMiddleware()

export type AppStartListening = TypedStartListening<RootState, AppDispatch>

export const startAppListening = listenerMiddleware.startListening as AppStartListening

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>

/**
 * Maps an RTK Query matcher to a Redux store slice
 *
 * @param matcher
 * Matcher for Query (i.e. `profileApi.endpoints.getProfile.matchFulfilled`)
 * @param storeAction
 * Action to dispatch to with the return value of the Query (i.e. `setProfile`)
 **/
export const rtkQueryToStore = (matcher: Matcher<AnyAction>, storeAction: ActionCreatorWithPayload<any>) => {
  startAppListening({
    matcher,
    effect: async (action, api) => {
      await api.dispatch(storeAction(action.payload))
    },
  })
}

analyticsListeners()
apiListeners()
checklistListeners()
userListeners()
notificationsListeners()
