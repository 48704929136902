import {
  AddCampaignToProfileRequest,
  AddMetadataRequest,
  CreateAccountRequest,
  CreateAccountRequestV2,
  CreateAccountRequestV3,
  CreateAccountResponse,
  GetConversationResponse,
  GetConversationsResponse,
  GetMetadataResponse,
  GetProfileResponse,
  GetPurchaseDetailsResponse,
  GetServiceRequestsResponse,
  ProfileItem,
  PurchaseDetails,
  SendEmojiFeedbackRequest,
  UpdateProfileAndConfirmRequest,
  UpdatePurchaseDetailsRequest,
} from '@home-in/models'
import { createApi } from '@reduxjs/toolkit/query/react'
import { homeApi } from '@redux/apis/home'
import { setUserProfile, setUserProfileOnce } from '@redux/reducers/analytics'
import { setWorkPartyLoaded } from '@redux/reducers/home'
import { setProfile } from '@redux/reducers/profile'
import { RootState } from '@redux/store'
import { trackEventsInApiCall } from '@redux/utils/analytics'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { baseQueryWithHeaders } from './custom-base-query'

const profileApiCacheTags = ['Profile', 'ServiceRequests', 'Conversations', 'Conversation', 'UserMetadata'] as const

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQueryWithHeaders('peacock'),
  tagTypes: profileApiCacheTags,
  endpoints: (builder) => ({
    createAccount: builder.mutation<CreateAccountResponse, CreateAccountRequest>({
      query: (body) => {
        return {
          url: 'v1/create-account',
          method: 'POST',
          body,
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: { action: AnalyticsEventAPICallEventNames.CreateAccount, category: AnalyticsCategories.Profile },
        })
      },
    }),
    createAccountV3: builder.mutation<void, CreateAccountRequestV3>({
      query: (body) => {
        return { url: 'v3/create-account', method: 'POST', body }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
        const existingProfile = (getState() as RootState).profile
        const { first_name, middle_name, last_name, utm_id } = props as CreateAccountRequestV3

        dispatch(setProfile({ ...existingProfile, first_name, middle_name, last_name, utm_id }))

        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: { action: AnalyticsEventAPICallEventNames.NameAndPhoneNumber, category: AnalyticsCategories.Profile },
          fulfilled: async () => {
            // When a user account is created in Pega, it sends an event back to Peacock with users' full profile data to update Jacaranda - resulting in a data sync latency in Peacock.
            // We want to poll the profile data to ensure that we have the most up-to-date information.
            let updatedProfile = (getState() as RootState).profile
            while (!updatedProfile?.email || !updatedProfile?.mobile) {
              await new Promise((resolve) => setTimeout(resolve, 1000))
              dispatch(profileApi.util.invalidateTags(['Profile']))
              updatedProfile = (getState() as RootState).profile
            }
          },
          undo: () => dispatch(setProfile(existingProfile)),
        })
      },
      invalidatesTags: ['Profile'],
    }),
    getProfile: builder.query<ProfileItem, void>({
      query: () => {
        return {
          url: 'v1/profile',
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
        await queryFulfilled
        const existingUserInfo = (getState() as RootState)?.profile
        dispatch(
          setUserProfile({
            utm_id: existingUserInfo.utm_id || '',
            campaigns: existingUserInfo?.campaigns?.map((campaign) => campaign.campaign_id) || [],
          })
        )
      },
      transformResponse: (response: { data: GetProfileResponse }) => response?.data?.Item,
      providesTags: ['Profile'],
    }),
    getUserMetadata: builder.query<GetMetadataResponse, void>({
      query: () => {
        return {
          url: 'v1/user-metadata',
        }
      },
      providesTags: ['UserMetadata'],
    }),
    updateUserProfile: builder.mutation<void, UpdateProfileAndConfirmRequest>({
      query: (body) => {
        return { url: 'v1/profile', method: 'PUT', body }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled, getState }) {
        const existingUserInfo = (getState() as RootState)?.profile
        const userProfileUpdate = props as Partial<ProfileItem>

        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: { action: AnalyticsEventAPICallEventNames.UpdatedProfile, category: AnalyticsCategories.Profile },
          fulfilled: () => {
            dispatch(setProfile({ ...existingUserInfo, ...userProfileUpdate, accepted_personal_information: true }))
            // Resolve any unlikely differences between database profile value and optimistic updated value
            setTimeout(() => {
              dispatch(profileApi.util.invalidateTags(['Profile']))
            }, 5000)
          },
        })
      },
    }),
    updatePurchaseDetails: builder.mutation<void, UpdatePurchaseDetailsRequest>({
      query: (body) => {
        return { url: 'v1/purchase-details', method: 'POST', body }
      },
      async onQueryStarted(purchaseDetails, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.UpdatedPurchaseDetails,
            category: AnalyticsCategories.Profile,
          },
          fulfilled: () => {
            dispatch(
              profileApi.util.updateQueryData('getPurchaseDetails', undefined, (draft) => {
                draft?.push({ user_confirmed: true, ...purchaseDetails })
              })
            )
          },
        })
      },
    }),
    acceptTermsAndConditions: builder.mutation<void, void>({
      query: () => {
        return { url: 'v1/accept-terms-and-conditions', method: 'POST' }
      },
      async onQueryStarted(acceptedTerms, { getState, dispatch, queryFulfilled }) {
        let { profile } = getState() as RootState

        dispatch(setProfile({ ...profile, accepted_terms: true }))

        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.AcceptedTermsAndConditions,
            category: AnalyticsCategories.Profile,
          },
          callback: () => {
            // We don't want to handle errors in T&C, use optimistic success
            dispatch(profileApi.util.invalidateTags(['Profile']))
          },
        })
        await queryFulfilled
        let { home } = getState() as RootState
        // retry these data if they are empty
        if (!home.hbj.length) {
          let isTeamDataAvailable = false
          const delays = [1, 1000, 2000, 4000, 8000]
          delays.forEach((delay) => {
            setTimeout(() => {
              ;({ home } = getState() as RootState)
              if (home.workParty.team.length > 0 && home.hbj.length > 0) {
                isTeamDataAvailable = true
                return
              }
              dispatch(homeApi.util.invalidateTags(['Home', 'Team']))
            }, delay)
          })
          setTimeout(() => {
            // Fallback to default and set loaded if polling failed.
            if (!isTeamDataAvailable) {
              dispatch(setWorkPartyLoaded(true))
            }
          }, 8005)
        }
      },
    }),
    addUserMetadata: builder.mutation<void, AddMetadataRequest>({
      query: (body) => {
        return { url: 'v1/user-metadata', method: 'POST', body }
      },
      invalidatesTags: ['UserMetadata'],
    }),
    addCampaignId: builder.mutation<void, AddCampaignToProfileRequest>({
      query: (body) => {
        return { url: 'v1/campaign', method: 'POST', body }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.AddCampaign,
            category: AnalyticsCategories.Profile,
            value: props.campaignId,
          },
        })
      },
      invalidatesTags: ['Profile'],
    }),
    getPurchaseDetails: builder.query<PurchaseDetails[] | null, void>({
      query: () => 'v1/purchase-details',
      transformResponse: (response: { data: GetPurchaseDetailsResponse } | null) => response?.data?.Items || null,
    }),
    sendEmojiFeedback: builder.mutation<void, SendEmojiFeedbackRequest>({
      query: (body) => {
        return { url: 'v1/emoji-feedback', method: 'POST', body }
      },
    }),
    getServiceRequests: builder.query<GetServiceRequestsResponse, void>({
      query: () => {
        return {
          url: 'v1/service-requests',
        }
      },
      transformResponse: (response: { data: GetServiceRequestsResponse }) => response?.data,
      providesTags: ['ServiceRequests'],
    }),
    getConversations: builder.query<GetConversationsResponse, void>({
      query: () => {
        return {
          url: 'v1/conversations',
        }
      },
      transformResponse: (response: { data: GetConversationsResponse }) => response?.data,
      providesTags: ['Conversations'],
    }),
    getConversation: builder.query<GetConversationResponse, { id: string }>({
      query: ({ id }) => {
        return {
          url: `v1/conversation/${id}`,
        }
      },
      transformResponse: (response: { data: GetConversationResponse }) => response?.data,
      providesTags: (_, __, { id }) => [{ type: 'Conversation', id }],
    }),
  }),
})

export const {
  useAcceptTermsAndConditionsMutation,
  useCreateAccountMutation,
  useGetProfileQuery,
  useGetPurchaseDetailsQuery,
  useGetServiceRequestsQuery,
  useGetConversationsQuery,
  useGetConversationQuery,
  useLazyGetProfileQuery,
  useSendEmojiFeedbackMutation,
  useUpdateUserProfileMutation,
  useUpdatePurchaseDetailsMutation,
  useCreateAccountV3Mutation,
  useGetUserMetadataQuery,
  useAddUserMetadataMutation,
  useAddCampaignIdMutation,
} = profileApi
