import { State, GetHomeBuyingJourneyHbj, GetHomeBuyingJourneyWorkPartyHbj, TransactionPhase } from '@home-in/models'
import { getSettledTransactions, getActiveTransactions } from '@utils/helpers/buy-properties.helpers'
import { getConveyancerNamesUsingHbjIds } from '@utils/helpers/team-helpers'

interface GetTransactionInfoForIntercomInterface {
  hbj: GetHomeBuyingJourneyHbj[]
  team?: GetHomeBuyingJourneyWorkPartyHbj[]
}

export const getActiveConveyancersValue = (activeConveyancersForBuy: (string | undefined)[]) => {
  const filteredConveyancers = activeConveyancersForBuy.filter((conveyancer) => conveyancer !== undefined)

  if (filteredConveyancers.length === 0) {
    return undefined
  }

  return filteredConveyancers.length === 1
    ? filteredConveyancers[0]
    : 'More than one conveyancer available, please check Customer profile in production platform'
}

export const getTransactionInfoForIntercom = ({ hbj, team }: GetTransactionInfoForIntercomInterface) => {
  const activeHbjs = getActiveTransactions({ hbj })
  const settledHbjs = getSettledTransactions({ hbj })
  const activeHbjIds = activeHbjs?.map((hbj) => hbj.hbj_id)
  const settledHbjIds = settledHbjs?.map((hbj) => hbj.hbj_id)
  const activeTransactionPhases = activeHbjs?.map((hbj) => hbj.transaction_phase)
  const activeConveyancersForBuy = getConveyancerNamesUsingHbjIds(activeHbjIds, team)

  return { activeHbjIds, settledHbjIds, activeTransactionPhases, activeConveyancersForBuy }
}

interface IntercomCustomAttributesHelperInterface {
  buyingAssistantName?: string | undefined
  state?: State | undefined
  address?: string
  settlementDate?: string | undefined
  activeHbj?: GetHomeBuyingJourneyHbj | undefined
  activeHbjIds?: string[] | undefined
  settledHbjIds?: string[] | undefined
  activeTransactionPhases?: TransactionPhase[] | undefined
  activeConveyancersForBuy?: (string | undefined)[] | undefined
}

export const getIntercomCustomAttributes = ({
  buyingAssistantName,
  state,
  address,
  settlementDate,
  activeHbjIds,
  settledHbjIds,
  activeTransactionPhases,
  activeConveyancersForBuy,
}: IntercomCustomAttributesHelperInterface) => {
  return {
    ...(!!buyingAssistantName ? { 'Buying Assistant': buyingAssistantName } : {}),
    ...(!!state ? { State: state } : {}),
    ...(!!address ? { Address: address } : {}),
    ...(!!settlementDate
      ? {
          'Settlement Date': new Date(settlementDate).toLocaleDateString('en-AU', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          }),
        }
      : {}),
    ...(!!activeHbjIds ? { 'Active HBJs': activeHbjIds.join(', ') } : {}),
    ...(!!settledHbjIds ? { 'Settled HBJs': settledHbjIds.join(', ') } : {}),
    ...(!!activeTransactionPhases ? { 'Active Transaction Phases': activeTransactionPhases.join(', ') } : {}),
    ...(!!activeConveyancersForBuy
      ? { 'Active conveyancers for buy': getActiveConveyancersValue(activeConveyancersForBuy) }
      : {}),
  }
}
