import {
  AddPropertyResponse,
  AddPropertySchemaType,
  CompleteTaskAddLenderDetailsRequest,
  CompleteTaskBaseRequest,
  CompleteTaskCompleteTransferDutyFormRequest,
  CompleteTaskConfirmBuyerVerificationDeclarationRequest,
  CompleteTaskConfirmContractTermsRequest,
  CompleteTaskConfirmStateOfPropertyRequest,
  CompleteTaskConfirmSubjectToSaleRequest,
  CompleteTaskContractReviewRequest,
  CompleteTaskEngageYourConveyancerRequest,
  CompleteTaskHomeBuyerBenefitsRequest,
  CompleteTaskPayConveyanceDutyRequest,
  CompleteTaskProvideContractOfSaleRequest,
  CompleteTaskProvideContractOfSaleRequestV2,
  CompleteTaskReviewAdviceRequest,
  CompleteTaskSignAndSendTransferDocumentRequest,
  CompleteTaskSignFrontOfContractRequest,
  CompleteTaskSignPurchaserDeclarationRequest,
  CompleteTaskSubjectToFinanceBaseValidationRequest,
  CompleteTaskVerifyYourIdentityRequest,
  GetChecklistResponse,
  GetChlResponse,
  GetDynamicDataResponse,
  LiveSignCustomer,
  PegaProcessChecklistTaskResponse,
  PropertySaleType,
  RemovePropertyRequest,
  RemovePropertyResponse,
  SliceProperty,
  State,
  Status,
  TaskType,
} from '@home-in/models'
import { Dispatch } from '@reduxjs/toolkit'
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { createApi } from '@reduxjs/toolkit/query/react'

import { Illustrations } from '@elements/icons/illustration'
import {
  buildChecklist,
  checkAllTasksAreIncluded,
  checkForDuplicateTasks,
} from '@features/checklist/data/checklist-builder'
import {
  WaratahPropertyWithChecklist,
  WaratahPropertyWithChecklistNoTasks,
} from '@features/checklist/data/checklist-data-types'
import { setPageReady } from '@redux/reducers/analytics'
import { addRemovedProperty, setChecklistPendingUpdates } from '@redux/reducers/checklist'
import { RootState } from '@redux/store'
import { trackEventsInApiCall } from '@redux/utils/analytics'
import { AnalyticsCategories, AnalyticsEventAPICallEventNames } from '@utils/helpers/analytics.enum'
import { getTasksFromChecklist } from '@utils/helpers/checklist.helpers'
import { baseQueryWithHeaders } from './custom-base-query'
import { homeApi } from './home'
import type { ContractReviewsSummaryResponse, WaratahRecordSchemaType } from '@home-in/models'

export const BooleanStates = {
  NSW: false,
  WA: false,
  QLD: false,
  VIC: false,
  SA: false,
  TAS: false,
  ACT: false,
  NT: false,
}

export const BooleanTaskStatus = {
  DISABLED: false,
  ENABLED: false,
  COMPLETED: false,
}

export const BooleanTypeOfSale = {
  AUCTION: false,
  PRIVATE_TREATY: false,
}

interface TaskCompleteArg {
  body:
    | CompleteTaskBaseRequest
    | CompleteTaskContractReviewRequest
    | CompleteTaskSignAndSendTransferDocumentRequest
    | CompleteTaskConfirmStateOfPropertyRequest
    | CompleteTaskConfirmSubjectToSaleRequest
    | CompleteTaskSignFrontOfContractRequest
    | CompleteTaskSignPurchaserDeclarationRequest
    | CompleteTaskHomeBuyerBenefitsRequest
    | CompleteTaskAddLenderDetailsRequest
    | CompleteTaskConfirmContractTermsRequest
    | CompleteTaskConfirmBuyerVerificationDeclarationRequest
    | CompleteTaskVerifyYourIdentityRequest
    | CompleteTaskReviewAdviceRequest
    | CompleteTaskCompleteTransferDutyFormRequest
    | CompleteTaskPayConveyanceDutyRequest
    | CompleteTaskProvideContractOfSaleRequest
    | CompleteTaskProvideContractOfSaleRequestV2
    | CompleteTaskEngageYourConveyancerRequest
    | CompleteTaskSubjectToFinanceBaseValidationRequest
  pathParams: TaskDetailQueryArg
  additionalPropsForEvents?: {
    requestContractReview?: 'email' | 'upload'
    state?: State
  }
}

export type GetChecklistQueryArg = {
  hbjId: string
  propertyId: string
}

export type GetAssignmentArg = {
  propertyId: string
  taskRef: string
}

export interface GuidanceArticleLink {
  title: string
  slug: string
  icon: Illustrations
}

export interface GetContractReviewSummaryArg {
  hbjId: string
}

export type TaskDetailQueryArg = {
  hbjId: string
  propertyId: string
  taskId: string
}

export type TaskCompletionQueryArg = {
  hbjId: string
  propertyId: string
  taskId: string
  referenceNumber: string
  categoryId: string
  taskType: TaskType
}

export interface RequestLiveSignResponse {
  customers: LiveSignCustomer[]
}

export type GetChecklistsResponse = {
  data: GetChecklistResponse
}[]

export interface GetWaratahChecklistResponse {
  checklists: WaratahRecordSchemaType[]
}

export type GetChecklistWaratahQueryArg = {
  getAllChecklist?: string
}

const checklistCacheTags = ['Home', 'Assignment', 'Documents', 'WaratahChecklist'] as const

export const checklistApi = createApi({
  reducerPath: 'checklistApi',
  baseQuery: baseQueryWithHeaders('lorikeet/v1/'),
  tagTypes: checklistCacheTags,
  endpoints: (builder) => ({
    getChecklist: builder.query<WaratahPropertyWithChecklist[], void>({
      query: () => {
        return '/checklist_waratah?getAllChecklist=true'
      },
      providesTags: ['WaratahChecklist'],
      keepUnusedDataFor: 0,
      transformResponse: (response: { data: GetWaratahChecklistResponse }) =>
        response?.data.checklists.map((property) => {
          checkForDuplicateTasks(property.checklist)
          const transformedPropertyNoTasks: WaratahPropertyWithChecklistNoTasks = {
            ...property,
            checklist: buildChecklist({
              tasks: property.checklist,
              contractSigned: property.contractSigned,
              state: property.state,
            }),
          }
          const transformedProperty = {
            ...transformedPropertyNoTasks,
            tasks: getTasksFromChecklist(transformedPropertyNoTasks),
          }
          checkAllTasksAreIncluded(transformedProperty.checklist, property.checklist)
          return transformedProperty
        }),
    }),
    completeCheckListTask: builder.mutation<PegaProcessChecklistTaskResponse, TaskCompleteArg>({
      query: ({ body, pathParams }) => ({
        url: `checklist/${pathParams.hbjId}/${pathParams.propertyId}/task/${pathParams.taskId}`,
        method: 'PATCH',
        body,
      }),

      async onQueryStarted({ additionalPropsForEvents }, { dispatch, queryFulfilled, getState }) {
        let title = (getState() as RootState).analytics.title
        if (title && title.toLowerCase().includes('subject to finance')) {
          title += ` - ${additionalPropsForEvents?.state}`
        }

        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: 'Complete Assignment Task',
            category: AnalyticsCategories.Checklist,
            value: title,
          },
        })

        // Temporary code to track the event for Request Contract Review
        if (additionalPropsForEvents?.requestContractReview) {
          await trackEventsInApiCall({
            dispatch,
            queryFulfilled,
            event: {
              action: `Request Contract Review - ${additionalPropsForEvents.requestContractReview}`,
              category: AnalyticsCategories.Checklist,
            },
          })
        }
        // This sets up a loading screen in the UI to get the latest checklist tasks
        setupChecklistPendingUpdates(dispatch)
        getNewChecklistWithCacheInvalidationPolling({
          tags: ['WaratahChecklist', 'Home', 'Documents'],
          dispatch,
        })
      },
      transformResponse: (response: { data: PegaProcessChecklistTaskResponse }) => response?.data,
    }),

    completeArticle: builder.mutation<void, TaskCompletionQueryArg>({
      query: ({ hbjId, propertyId, taskId, ...params }) => ({
        url: `checklist/${hbjId}/${propertyId}/task/${taskId}`,
        method: 'PATCH',
        body: {
          status: Status.COMPLETED,
          taskReferenceId: params.referenceNumber,
          categoryId: params.categoryId,
          taskType: params.taskType,
        },
      }),
      invalidatesTags: ['WaratahChecklist'],
      onQueryStarted: async (body, { dispatch, queryFulfilled, getState }) => {
        const title = (getState() as RootState).analytics.title

        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            // Event name is dynamic because the task information comes from an API
            action: `Complete Confirmation Task`,
            category: AnalyticsCategories.Checklist,
            value: title,
          },
          fulfilled: () => dispatch(setPageReady(false)),
        })

        // Some tasks get enabled after you complete an information task. That's why we need this cache invalidation.
        getNewChecklistWithCacheInvalidationPolling({
          tags: ['WaratahChecklist'],
          dispatch,
          delays: [500, 1500, 5000],
        })
      },
    }),

    // endpoint serves dynamic content to assignment tasks
    getAssignmentDynamicContent: builder.query<GetDynamicDataResponse, GetAssignmentArg>({
      query: ({ propertyId, taskRef }) => `checklist/${propertyId}/assignment/${taskRef}`,
      keepUnusedDataFor: 5,
      providesTags: ['Assignment'],
      transformResponse: (response: { data: GetDynamicDataResponse }) => response?.data,
    }),

    getChl: builder.query<GetChlResponse, string>({
      query: (id) => `property/chl/cba/${id}`,
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: GetChlResponse }) => response?.data,
    }),

    addProperty: builder.mutation<SliceProperty, AddPropertySchemaType>({
      // Lorikeet: Add A Property API
      // https://projectwavec.atlassian.net/wiki/spaces/EN/pages/143360381
      query: (body) => ({ url: 'property', method: 'POST', body }),
      transformResponse: (
        response: { message: string; data: AddPropertyResponse },
        _,
        arg: AddPropertySchemaType
      ): SliceProperty => {
        return {
          hbjId: response.data.hbjid,
          propertyId: response.data.propertyid,
          property: {
            addressKey: arg.address_key || '',
            address: arg.address?.full_address || '',
            imageUrl: arg.property_image_url || '',
            auctionDate: arg.auction_date || '',
            contractExchanged: arg.are_contracts_exchanged!,
            saleType: arg.sale_type === PropertySaleType.Auction ? 'auction' : 'private_treaty',
            state: arg.address?.state as State,
            removable: true,
            hasCosReady: arg.has_cos_ready,
          },
        }
      },
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.AddProperty,
            category: AnalyticsCategories.Property,
          },
        })

        // Refresh properties list to get the latest value for `removable` of the newly added property
        setTimeout(() => {
          dispatch(checklistApi.util.invalidateTags(['WaratahChecklist']))
        }, 5000)
        getNewChecklistWithCacheInvalidationPolling({ tags: ['WaratahChecklist', 'Home'], dispatch })

        await queryFulfilled
        dispatch(homeApi.util.invalidateTags(['Team']))
        // This solves any race conditions where Pega doesn't have enough time to update the lawyer before the first API call to 'Team'
        setTimeout(() => {
          dispatch(homeApi.util.invalidateTags(['Team']))
        }, 5000)
      },
    }),

    removeProperty: builder.mutation<RemovePropertyResponse, RemovePropertyRequest>({
      // Lorikeet: Remove Property API
      // https://projectwavec.atlassian.net/wiki/spaces/EN/pages/143360381
      query: (body) => ({ url: 'property', method: 'PATCH', body }),
      async onQueryStarted({ hbj, propertyId }, { dispatch, queryFulfilled, getState }) {
        const waratahProperties = (getState() as RootState)?.checklist?.checklists
        const removedPropertyWaratah = waratahProperties?.find(
          (props) => props?.hbjId === hbj && props?.propertyId === propertyId
        )
        if (!!removedPropertyWaratah) dispatch(addRemovedProperty(removedPropertyWaratah))

        trackEventsInApiCall({
          dispatch,
          queryFulfilled,
          event: {
            action: AnalyticsEventAPICallEventNames.RemoveProperty,
            category: AnalyticsCategories.Property,
          },
        })
      },
    }),
    requestLivesign: builder.mutation<RequestLiveSignResponse, GetChecklistQueryArg>({
      query: ({ hbjId, propertyId }) => {
        return { url: `checklist/${hbjId}/${propertyId}/verify-identity`, method: 'POST' }
      },
      // TODO: automated re-fetching & failover i.e. short polling handled in: https://home-in.atlassian.net/browse/BER-686?atlOrigin=eyJpIjoiNmUxY2E5ZmYzZTgzNDMzODg0N2M4M2M4MWRjNzRiYmEiLCJwIjoiaiJ9
      invalidatesTags: ['Assignment'],
      transformResponse: (response: { data: RequestLiveSignResponse }) => response?.data,
    }),

    getContractReviewsSummary: builder.query<ContractReviewsSummaryResponse, GetContractReviewSummaryArg>({
      query: ({ hbjId }) => `contract-reviews/${hbjId}/summary`,
      keepUnusedDataFor: 5,
      transformResponse: (response: { data: ContractReviewsSummaryResponse }) => response?.data,
    }),
  }),
})

interface GetNewChecklistWithCacheInvalidationPollingProps {
  dispatch: Dispatch
  tags: TagDescription<(typeof checklistCacheTags)[number]>[]
  delays?: number[]
}

const setupChecklistPendingUpdates = (dispatch: Dispatch) => {
  dispatch(setChecklistPendingUpdates(true))
  setTimeout(() => {
    dispatch(setChecklistPendingUpdates(false))
    /* TODO: Update this from a hardcoded value of 6.5 seconds to a dynamic value once we get back data from the API */
  }, 6500)
}

/**
 * When we get the updated checklist data, cannot be predicted due to the latency between the event bus and dynamodb
 * This function sets up optimal polling to invalidate the cache to get fresh data for most user scenarios
 */
const getNewChecklistWithCacheInvalidationPolling = ({
  dispatch,
  tags,
  delays = [100, 2000, 4000, 6000, 8000, 10000],
}: GetNewChecklistWithCacheInvalidationPollingProps) => {
  delays.forEach((delay) => {
    setTimeout(() => {
      dispatch(checklistApi.util.invalidateTags(tags))
    }, delay)
  })
}

export const {
  useGetChecklistQuery,
  useCompleteCheckListTaskMutation,
  useGetAssignmentDynamicContentQuery,
  useLazyGetChlQuery,
  useAddPropertyMutation,
  useCompleteArticleMutation,
  useRemovePropertyMutation,
  useRequestLivesignMutation,
  useGetContractReviewsSummaryQuery,
} = checklistApi
