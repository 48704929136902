import { useAppSelector } from '@redux/hooks'
import { useChecklistRouteParams } from './useChecklistRouteParams'

const useActiveHbj = () => {
  const customerHbjs = useAppSelector((state) => state.home.hbj)
  const { hbjId: activeHbjId } = useChecklistRouteParams()

  const activeHbj = customerHbjs?.find((hbj) => hbj.hbj_id === activeHbjId)
  return { activeHbj, activeHbjId }
}

export default useActiveHbj
