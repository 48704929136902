import { DateTime } from 'luxon'

const PEGA_DATETIME_FORMAT = "yyyyLLdd'T'HHmmss.SSS 'GMT'"

const timeZoneMap: any = {
  WA: 'Perth',
  NT: 'Darwin',
  SA: 'Adelaide',
  QLD: 'Brisbane',
  NSW: 'Sydney',
  ACT: 'Canberra',
  TAS: 'Hobart',
  VIC: 'Melbourne',
}

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const formatDate = (date: string | undefined): string => {
  if (!date || date === '') return ''

  const isPegaDate = date.endsWith('GMT')
  const newDate = isPegaDate
    ? DateTime.fromFormat(date, PEGA_DATETIME_FORMAT, { zone: 'GMT' }).toJSDate()
    : new Date(date)

  const day = newDate.getDate()
  const month = months[newDate.getMonth()]
  const year = newDate.getFullYear()

  return `${day} ${month}, ${year}`
}

export const formatPegaDate = (date: string): string => {
  return date?.replaceAll('-', '')
}

// input expects "19901231"
// outputs "1990-12-31"
export const formatFormDate = (date: string): string => {
  const chars = date.split('')
  return `${chars.slice(0, 4).join('')}-${chars[4] + chars[5]}-${chars[6] + chars[7]}`
}

export const eighteenYearsAgo = (): string => {
  const date = new Date()
  const eighteenYearsAgo = date.getFullYear() - 18
  date.setFullYear(eighteenYearsAgo)
  return formatDateToFormField(date)
}

export const sevenDaysAgo = (): string => {
  const date = new Date()
  date.setDate(date.getDate() - 7)
  return `${date.toISOString().split('T')[0]}T00:00`
}

export const formatDob = (date: string | undefined): string => {
  if (!date || typeof date !== 'string' || date?.length !== 8) return ''
  return [date.slice(6, 8), '/', date.slice(4, 6), '/', date.slice(0, 4)].join('')
}

export const formatPegaDateToDateField = (date: string): string => {
  if (!date || typeof date !== 'string' || date?.length !== 8) return ''
  return [date.slice(0, 4), '-', date.slice(4, 6), '-', date.slice(6, 8)].join('')
}

export const formatDateToFormField = (date: Date): string => {
  return date.toISOString().split('T')?.[0] || ''
}

export const formatDateTime = (date: string, showTime?: boolean, state?: string): string => {
  if (!date) return date
  const isPegaDate = date.endsWith('GMT')
  const dateObj = isPegaDate
    ? DateTime.fromFormat(date, PEGA_DATETIME_FORMAT, { zone: 'GMT' }).toJSDate()
    : new Date(date)
  if (!(dateObj instanceof Date && !isNaN(dateObj as unknown as number))) return date
  const day = dateObj.toLocaleDateString('en-AU', { day: 'numeric' })
  const month = dateObj.toLocaleDateString('en-AU', { month: 'short' })
  const year = dateObj.toLocaleDateString('en-AU', { year: 'numeric' })
  const parsedDate = `${day} ${month} ${year}`
  if (!showTime) return parsedDate

  const timeZone = state ? `Australia/${timeZoneMap[state]}` : undefined
  const rawTime = dateObj.toLocaleTimeString('en-AU', {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
    ...(timeZone ? { timeZone } : null),
  })
  const [time, period, timezone] = rawTime.split(' ')
  const parsedDateTime = `${parsedDate} at ${time} ${period.toUpperCase()} (${timezone})`
  return parsedDateTime
}

export const formatToShortDate = (date: string): string => {
  const dateObj = new Date(date)
  const dateString = dateObj.toLocaleString('en-AU').split(',')[0]
  const [day, month, year] = dateString.split('/')
  return [day, month, year.slice(2)].join('/')
}

export const isEligibleForSellOffer = (
  taskCompletedTime: string | undefined,
  currentTime: Date = new Date()
): boolean => {
  const endOfOfferDate = new Date('2024-06-29T14:00:00.000Z') // Sydney time 30th June
  const taskCompletedAtTime = new Date(taskCompletedTime || '')
  return isNaN(taskCompletedAtTime.getTime()) ? currentTime < endOfOfferDate : taskCompletedAtTime < endOfOfferDate
}

export const isOfferExpired = (expiryDate: string): boolean => {
  const today = new Date()
  const expiration = new Date(expiryDate)
  return today > expiration
}

//https://www.freecodecamp.org/news/format-dates-with-ordinal-number-suffixes-javascript/#:~:text=In%20this%20short%20article%2C%20you,you%20need%20to%20order%20something.
export const ordinalDateSuffix = (number: number) => {
  if (number > 3 && number < 21) return 'th'
  switch (number % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const formatDateWithOrdinalSuffix = (date: string) => {
  const expiryDate = new Date(date)
  const day = expiryDate.getUTCDate()
  const month = months[expiryDate.getUTCMonth()]
  const year = expiryDate.getUTCFullYear()
  return `${day}${ordinalDateSuffix(day)} ${month} ${year}`
}
