import MaterialUIButton, { ButtonOwnProps } from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { HTMLAttributes, PropsWithChildren, useState } from 'react'
import { Icon, Icons } from '@elements/icons/icon'
import { classNames } from '@utils/helpers/classNameHelper'
import type { ButtonHTMLAttributes } from 'react'

export type ButtonStyle = keyof typeof buttonStyle
export type ButtonSize = keyof typeof buttonSize

enum buttonStyle {
  primary,
  secondary,
  secondary_alt,
  gradient,
}

enum buttonSize {
  sm,
  md,
  lg,
  full,
  dynamic,
}

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  link?: string
  callback?: () => any
  disabled?: boolean
  category?: ButtonStyle
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  size?: ButtonSize
  muiSize?: ButtonOwnProps['size']
  name?: string
  isLoading?: boolean
  variant?: 'outlined' | 'contained'
  color?: 'primary' | 'secondary'
  disableElevation?: boolean
  icon?: Icons
  overrideFullWidth?: boolean
  overrideDefaultWidth?: boolean
  rounded?: boolean
  form?: string
}

const translateCategoryToVariant = (category: string) => {
  switch (category) {
    case 'primary':
      return 'contained'
    case 'secondary':
    case 'secondary_alt':
      return 'outlined'
    default:
      return 'contained'
  }
}

const buttonStyleMapping = (category: keyof typeof buttonStyle) => ({
  'bg-gradient-to-r from-[#017F76] to-[#00385B]': category === 'gradient',
})

export const Button = ({
  callback,
  children,
  disabled = false,
  category = 'primary',
  type = 'button',
  size = 'md',
  muiSize = undefined,
  className,
  link,
  name,
  isLoading = false,
  icon = undefined,
  overrideFullWidth = false,
  overrideDefaultWidth = false,
  rounded = false,
  form,
}: PropsWithChildren<ButtonProps>) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  if (link) {
    return (
      <MaterialUIButton
        data-testid={`button-${name}`}
        className={classNames(buttonStyleMapping(category))}
        fullWidth={isSmallScreen || overrideFullWidth}
        type={type}
        disableElevation
        variant={translateCategoryToVariant(category)}
        onClick={callback}
        disabled={disabled || isLoading}
        size={muiSize || 'large'}
        sx={{
          '&.MuiButton-root': {
            ...(!overrideDefaultWidth && { minWidth: '12.5rem' }),
            ...(rounded && { borderRadius: '21px' }),
          },
        }}
        form={form}
      >
        <a href={link} target="_blank" rel="noreferrer">
          {isLoading ? (
            <CircularProgress size={24} />
          ) : icon ? (
            <div className="flex">
              {icon && <Icon asset={icon} size={24} className="mr-4 shrink-0" />}
              {children}
            </div>
          ) : (
            children
          )}
        </a>
      </MaterialUIButton>
    )
  }
  return (
    <div className={classNames('relative', className)}>
      <MaterialUIButton
        data-testid={`button-${name}`}
        className={classNames(buttonStyleMapping(category))}
        fullWidth={isSmallScreen || overrideFullWidth}
        type={type}
        disableElevation
        variant={translateCategoryToVariant(category)}
        onClick={callback}
        disabled={disabled || isLoading}
        size={muiSize || 'large'}
        sx={{
          '&.MuiButton-root': {
            ...(!overrideDefaultWidth && !muiSize && { minWidth: '12.5rem' }),
            ...(rounded && { borderRadius: '21px' }),
          },
        }}
        form={form}
      >
        {isLoading ? (
          <CircularProgress size={24} />
        ) : icon ? (
          <div className="flex">
            {icon && <Icon asset={icon} size={24} className="mr-4 shrink-0" />}
            {children}
          </div>
        ) : (
          children
        )}
      </MaterialUIButton>
    </div>
  )
}
