import { TransactionPhase } from '@home-in/models'
import mixpanelSDK from 'mixpanel-browser'
import TagManager from 'react-gtm-module'
import { AnalyticsCategories, AnalyticsEventAPICallPrefixes } from '@utils/helpers/analytics.enum'
import Package from '../../../package.json'
import type {
  AnalyticsContext,
  AnalyticsEvent,
  AnalyticsEventWithPlatform,
  DeviceInfo,
} from '@utils/helpers/analytics.interface'

// Required for window.dataLayer.push() otherwise type error
declare const window: any

const mixpanel = mixpanelSDK

export const analytics: AnalyticsContext = { mixpanel }

export const initAnalytics = () => {
  analytics.mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_ID!, {
    debug: !!process.env.NEXT_PUBLIC_MIXPANEL_DEBUG,
    ignore_dnt: true,
    batch_requests: false,
  })

  /**
   * If we are inside an Iframe, exclude from GTM as on the main marketing site
   * they are already tracked using GTM inside of Webflow
   */
  if (window.location === window.parent.location) {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID! })
  }
}

export const aliasUser = (id: string | undefined) => {
  if (!id) return

  analytics.mixpanel.alias(id)
}
export const identifyUser = (id: string | undefined) => {
  if (!id) return

  analytics.mixpanel.identify(id)
  TagManager.dataLayer({ dataLayer: { userId: id } })
  if (window?.clarity) {
    window?.clarity('identify', id)
  }
}

export const setOnceUserProfile = (attribute: Record<string, string> | undefined) => {
  if (!attribute) return
  analytics.mixpanel.people.set_once(attribute)
}

export const doSetUserProfile = (attribute: Record<string, string | string[] | boolean> | undefined) => {
  if (!attribute) return
  analytics.mixpanel.people.set(attribute)
}

const trackPageViewCall = (
  event: AnalyticsEvent,
  context: AnalyticsContext,
  platform: string,
  deviceInfo?: DeviceInfo,
  phase?: TransactionPhase
) => {
  if (!event.action || !context) {
    return
  }
  context.mixpanel.track(
    `Page Viewed: ${event.action} | Home-in`,
    {
      category: event.category,
      platform,
      system: 'Reboot',
      version: Package.version,
      deviceBrand: deviceInfo?.brand,
      deviceModel: deviceInfo?.modelName,
      deviceSystemVersion: deviceInfo?.systemVersion,
      phase,
      transaction_type: event.transactionType,
    },
    { send_immediately: true }
  )
}

const trackEventCall = (
  event: AnalyticsEvent,
  context: AnalyticsContext | null,
  platform: string,
  deviceInfo?: DeviceInfo,
  phase?: TransactionPhase
) => {
  if (!event.action || !event.category || !context) {
    return
  }

  const value = typeof event?.value === 'object' ? { ...event?.value } : { value: event?.value }

  context.mixpanel.track(
    event.action,
    {
      category: event.category,
      ...value,
      platform,
      system: 'Reboot',
      version: Package.version,
      deviceBrand: deviceInfo?.brand,
      deviceModel: deviceInfo?.modelName,
      deviceSystemVersion: deviceInfo?.systemVersion,
      phase,
      transaction_type: event.transactionType,
    },
    { send_immediately: true }
  )

  window.dataLayer.push({
    event: event?.action,
    category: event?.category,
    ...value,
  })
}

export const doTrackEvent = ({
  action,
  category,
  platform,
  value,
  deviceInfo,
  phase,
  transactionType,
}: AnalyticsEventWithPlatform) => {
  trackEventCall({ action, category, value, transactionType }, analytics, platform, deviceInfo, phase)
}

export const doTrackPageView = ({
  action,
  category = AnalyticsCategories.PageView,
  value,
  platform,
  deviceInfo,
  phase,
  transactionType,
}: AnalyticsEventWithPlatform) => {
  trackPageViewCall({ action, category, value, transactionType }, analytics, platform, deviceInfo, phase)
}

export const doTrackButtonClick = ({
  action,
  category,
  platform,
  value,
  deviceInfo,
  phase,
  transactionType,
}: AnalyticsEventWithPlatform) => {
  trackEventCall(
    { action: `${AnalyticsEventAPICallPrefixes.ButtonClick}${action}`, category, value, transactionType },
    analytics,
    platform,
    deviceInfo,
    phase
  )
}

export const doTrackRequestStart = ({
  action,
  category,
  platform,
  value,
  deviceInfo,
  phase,
}: AnalyticsEventWithPlatform) => {
  trackEventCall(
    { action: `${AnalyticsEventAPICallPrefixes.RequestStarted}${action}`, category, value },
    analytics,
    platform,
    deviceInfo,
    phase
  )
}

export const doTrackRequestSuccess = ({
  action,
  category,
  platform,
  value,
  deviceInfo,
  phase,
}: AnalyticsEventWithPlatform) => {
  trackEventCall(
    { action: `${AnalyticsEventAPICallPrefixes.RequestSucceeded}${action}`, category, value },
    analytics,
    platform,
    deviceInfo,
    phase
  )
}

export const doTrackRequestFailure = ({
  action,
  category,
  platform,
  value,
  deviceInfo,
  phase,
}: AnalyticsEventWithPlatform) => {
  trackEventCall(
    { action: `${AnalyticsEventAPICallPrefixes.RequestFailed}${action}`, category, value },
    analytics,
    platform,
    deviceInfo,
    phase
  )
}

export const doTrackClientError = ({
  action,
  category = AnalyticsCategories.ClientError,
  platform,
  value,
  deviceInfo,
  phase,
}: AnalyticsEventWithPlatform) => {
  trackEventCall(
    { action: `${AnalyticsEventAPICallPrefixes.ClientError}${action}`, category, value },
    analytics,
    platform,
    deviceInfo,
    phase
  )
}
