import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const useChecklistRouteParams = () => {
  const router = useRouter()

  const params = ['categoryId', 'hbjId', 'propertyId', 'taskId', 'sellPropertyId'] as const

  /**
   * Test that the input of our route parameters matches our pattern:
   *
   * categoryId: CAT00 to CAT99999
   * hbjId: HBJ-00 to HBJ-999999
   * propertyId: PB-00 to PB-999999
   * taskId: ACT00 to ACT99999
   */
  const regex: { [K in (typeof params)[number]]: RegExp } = {
    categoryId: /CAT\d{2,5}/,
    hbjId: /HBJ-\d{2,6}/,
    propertyId: /PB-\d{2,6}/,
    taskId: /(ACT|CUST)\d{2,5}/,
    sellPropertyId: /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/,
  }

  const result: { [K in (typeof params)[number]]: string } = {
    categoryId: '',
    hbjId: '',
    propertyId: '',
    taskId: '',
    sellPropertyId: '',
  }

  return useMemo(() => {
    params.forEach((param) => {
      const routerParam = String(router.query?.[param] || '')

      result[param] = regex[param].test(routerParam) ? routerParam : ''
    })

    return result
  }, [])
}
