import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { AnalyticsEvent } from '@utils/helpers/analytics.interface'

interface AnalyticsStore {
  bypassDefaultPageView: boolean
  initialised: boolean
  pageIsReady: boolean
  title: string | undefined
}

export const analytics = createSlice({
  name: 'analytics',
  initialState: <AnalyticsStore>{
    bypassDefaultPageView: false,
    initialised: false,
    pageIsReady: false,
    title: undefined,
  },
  reducers: {
    // These are all null actions as we are just using them to trigger async events
    initAnalytics: (_) => {},
    trackEvent: (_, __: PayloadAction<AnalyticsEvent>) => {},
    trackPageView: (_, __: PayloadAction<AnalyticsEvent>) => {},
    trackButtonClick: (_, __: PayloadAction<AnalyticsEvent>) => {},
    trackRequestStart: (_, __: PayloadAction<AnalyticsEvent>) => {},
    trackRequestSuccess: (_, __: PayloadAction<AnalyticsEvent>) => {},
    trackRequestFailure: (_, __: PayloadAction<AnalyticsEvent>) => {},
    trackClientError: (_, __: PayloadAction<AnalyticsEvent>) => {},
    setAnalyticsInitialised: (state, action: PayloadAction<boolean>) => {
      state.initialised = action.payload
    },
    setPageReady: (state, action: PayloadAction<boolean>) => {
      state.pageIsReady = action.payload
    },
    setBypassDefaultPageView: (state, action: PayloadAction<boolean>) => {
      state.bypassDefaultPageView = action.payload
    },
    setUserAlias: (_, __: PayloadAction<string | undefined>) => {},
    setUserProfile: (_, __: PayloadAction<Record<string, string | string[] | boolean> | undefined>) => {},
    setUserProfileOnce: (_, __: PayloadAction<Record<string, string> | undefined>) => {},
    setUserIdentifier: (_, __: PayloadAction<string | undefined>) => {},
    updateAnalyticsTitle: (state, { payload }: PayloadAction<string | undefined>) => {
      state.title = payload
    },
  },
})

export const {
  initAnalytics,
  setAnalyticsInitialised,
  setBypassDefaultPageView,
  setPageReady,
  setUserAlias,
  setUserProfileOnce,
  setUserProfile,
  setUserIdentifier,
  trackEvent,
  trackPageView,
  trackButtonClick,
  trackRequestStart,
  trackRequestSuccess,
  trackRequestFailure,
  trackClientError,
  updateAnalyticsTitle,
} = analytics.actions
export default analytics.reducer
