import { useFlags } from 'launchdarkly-react-client-sdk'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, PropsWithChildren } from 'react'
import { useIntercom } from 'react-use-intercom'
import Illustration, { Illustrations } from '@elements/icons/illustration'
import { useAppSelector, useAppDispatch } from '@redux/hooks'
import { trackButtonClick } from '@redux/reducers/analytics'
import { AnalyticsCategories } from '@utils/helpers/analytics.enum'
import { classNames } from '@utils/helpers/classNameHelper'
import Logo from './logo'

const MobileNavigationContainer: FC<{}> = ({ children }) => (
  <nav className="fixed bottom-0 z-50 grid w-full select-none grid-cols-5 bg-white py-2 shadow-navShadow md:hidden">
    {children}
  </nav>
)

export const Navigation = () => {
  const { guidanceIndex } = useFlags()
  const { hide, isOpen, show } = useIntercom()
  const dispatch = useAppDispatch()
  const router = useRouter()

  const homeIsCurrent =
    router.route === '/' ||
    router.route.startsWith('/checklist') ||
    router.route.startsWith('/your-team') ||
    router.route.startsWith('/sell')

  const menuItems: MenuItem[] = [
    {
      key: 'home',
      name: 'Home',
      illustration: Illustrations.home,
      url: '/',
      visible: true,
      isCurrent: homeIsCurrent,
    },
    {
      key: 'guidance',
      name: 'Guidance',
      illustration: Illustrations.place,
      url: '/guidance',
      visible: guidanceIndex,
      isCurrent: router.route.startsWith('/guidance'),
    },
    {
      key: 'intercom',
      name: 'Get in touch',
      illustration: Illustrations.messages,
      visible: true,
      callback: () => {
        if (isOpen) hide()
        else {
          dispatch(
            trackButtonClick({
              action: 'Get in touch',
              category: AnalyticsCategories.GetInTouch,
            })
          )
          show()
        }
      },
      isCurrent: isOpen,
    },
    {
      key: 'documents',
      name: 'Documents',
      illustration: Illustrations.document,
      visible: true,
      url: '/your-documents',
      isCurrent: router.route.startsWith('/your-documents'),
    },
    {
      key: 'profile',
      name: 'Profile',
      illustration: Illustrations.person,
      url: '/profile',
      visible: true,
      isCurrent: router.route.startsWith('/profile'),
    },
  ]

  return (
    <>
      <aside className="fixed hidden h-full w-60 select-none bg-white md:block">
        <Link href={'/'} passHref>
          <a>
            <Logo innerClassName="w-[8.5rem]" />
          </a>
        </Link>
        <nav>
          {menuItems
            .filter((item) => item.visible)
            .map((item) => (
              <SidebarItem key={item.key} item={item} />
            ))}
        </nav>
      </aside>
      <MobileNavigationContainer>
        {menuItems
          .filter((item) => item.visible)
          .map((item) => (
            <MobileNavItem key={item.key} item={item} />
          ))}
      </MobileNavigationContainer>
    </>
  )
}

const NewMessagesBubble = () => {
  const unreadIntercomMessages = useAppSelector((state) => state.notifications.intercomUnread)

  return !!unreadIntercomMessages ? (
    <div className="absolute -top-px left-[60%] flex h-[10px] w-[10px] items-center justify-center rounded-full bg-error text-center md:left-[32px] md:top-[12px]"></div>
  ) : null
}

const MobileNavItem = ({ item }: { item: MenuItem }) => {
  const content = (
    <a className="mobile-padding-bottom relative flex cursor-pointer flex-col items-center justify-center">
      <Illustration
        asset={item.illustration}
        customColour={!item.isCurrent ? 'muted' : undefined}
        className="mb-2"
        size={24}
      />
      {item.key === 'intercom' && <NewMessagesBubble />}
      <div className={`text-center text-xs tracking-tighter ${item.isCurrent && 'font-bold'}`}>{item.name}</div>
    </a>
  )

  return (
    (item?.url && (
      <Link href={item.url} key={item.name} passHref>
        {content}
      </Link>
    )) ||
    (item?.callback && (
      <div role="button" className="mobile-padding-bottom p-0.5" onClick={item.callback}>
        {content}
      </div>
    )) || <></>
  )
}

export interface MenuItem {
  key: string
  name: string
  url?: string
  callback?: () => void
  illustration: Illustrations
  isExternalLink?: boolean
  visible: boolean
  isCurrent: boolean
}

export interface SidebarItemProps {
  item: MenuItem
}

const SidebarItem = ({ item }: PropsWithChildren<SidebarItemProps>) => {
  const content = (
    <a
      className={classNames(
        'flex p-4 border-b border-b-text-100 duration-200 transition-colors hover:opacity-50 transition-all relative',
        item.isCurrent ? 'font-semibold bg-light' : 'font-medium text-muted'
      )}
    >
      <div className={classNames('mr-4 duration-500')}>
        <Illustration asset={item.illustration} customColour={!item.isCurrent ? 'muted' : undefined} size={20} />
      </div>
      {item.key === 'intercom' && <NewMessagesBubble />}

      {item.name}
    </a>
  )

  return (
    (item?.url && (
      <Link href={item.url} key={item.name} passHref>
        {content}
      </Link>
    )) ||
    (item?.callback && (
      <div role="button" className="cursor-pointer" onClick={item.callback}>
        {content}
      </div>
    )) || <></>
  )
}
