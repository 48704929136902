import React, { HTMLAttributes } from 'react'
import { classNames } from '@utils/helpers/classNameHelper'

interface ICard extends HTMLAttributes<HTMLDivElement> {
  variant?: keyof typeof variantStyles
  size?: keyof typeof cardSize
}

const variantStyles = {
  normal: 'bg-white shadow-customCard rounded',
  feature: 'bg-gradient-to-r from-secondary-variant-600 to-primary-variant-400 shadow-customCard rounded text-white',
  alt: 'bg-background-dark-50 border border-muted/10 rounded',
  'mobile-no-style': 'px-2 md:bg-white md:shadow-customCard md:rounded md:p-8',
  light: 'bg-light rounded',
  'conveyancing-costs': 'bg-white rounded-xl mb-4 border border-muted border-opacity-25',
}

const cardSize = {
  xs: 'p-3 md:p-4',
  small: 'p-4 md:p-5',
  medium: 'p-6 md:p-8',
  none: '',
}

export const Card = ({ children, className, size = 'medium', variant = 'normal', ...rest }: ICard) => {
  return (
    <div className={classNames('empty:hidden', variantStyles[variant], cardSize[size], className)} {...rest}>
      {children}
    </div>
  )
}
