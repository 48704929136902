import { useEffect, useRef } from 'react'
import { useIntercom } from 'react-use-intercom'
import { useMobileQuery } from '@hooks/layoutHooks'
import useActiveWorkParty from '@hooks/useActiveWorkParty'
import { useCurrentChecklist } from '@hooks/useCurrentChecklist'
import { useAppSelector } from '@redux/hooks'
import { getIntercomCustomAttributes, getTransactionInfoForIntercom } from '@utils/helpers/intercom.helpers'

/**
 * This hook sets up the Intercom chat widget with the user's profile information
 */
const useIntercomSetup = () => {
  const intercom = useIntercom()
  const profile = useAppSelector((state) => state.profile)
  const hbj = useAppSelector((state) => state.home.hbj)
  const { team } = useAppSelector((state) => state.home.workParty)
  const booted = useRef(false)
  const { activeWorkParty } = useActiveWorkParty()
  const isMobile = useMobileQuery()
  const waratahProperty = useCurrentChecklist()
  const state = waratahProperty?.state
  const address = waratahProperty?.address
  const hbjId = waratahProperty?.hbjId
  const settlementDate = waratahProperty?.settlementDateTime
  const buyingAssistantName = activeWorkParty?.your_personal_contact?.full_name

  const { activeHbjIds, settledHbjIds, activeTransactionPhases, activeConveyancersForBuy } =
    getTransactionInfoForIntercom({ hbj, team })

  useEffect(() => {
    if (!booted.current && profile && profile?.id && profile?.email) {
      intercom.boot({
        name: profile.first_name + ' ' + profile.last_name,
        phone: profile.mobile,
        email: profile.email,
        userId: profile.id,
        userHash: profile?.chatKey,
        hideDefaultLauncher: isMobile,
      })
      booted.current = true
    }
  }, [profile])

  useEffect(() => {
    if (booted.current) {
      intercom.update({ hideDefaultLauncher: isMobile })
    }
  }, [booted.current, isMobile])

  useEffect(() => {
    // Update the user's Intercom profile with BA and Conveyancer information
    // Intercom uses title case keys for custom attributes
    if (
      booted.current &&
      (buyingAssistantName ||
        state ||
        settlementDate ||
        address ||
        hbjId ||
        activeHbjIds ||
        settledHbjIds ||
        activeConveyancersForBuy)
    ) {
      const customAttributes = getIntercomCustomAttributes({
        buyingAssistantName,
        state,
        address,
        settlementDate,
        activeHbjIds,
        settledHbjIds,
        activeTransactionPhases,
        activeConveyancersForBuy,
      })

      intercom.update({ customAttributes })
    }
  }, [
    activeWorkParty,
    booted.current,
    profile.id,
    settlementDate,
    state,
    address,
    hbjId,
    activeHbjIds,
    settledHbjIds,
    activeHbjIds,
    settledHbjIds,
    activeConveyancersForBuy,
    hbj,
    team,
  ])
}

export default useIntercomSetup
